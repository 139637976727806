@import "https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap";

.wrapper-invoice {
  display: flex;
  justify-content: center;
}

.wrapper-invoice .invoice {
  height: auto;
  background: #fff;
  padding: 5vh;
  margin-top: 5vh;
  max-width: 110vh;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #dcdcdc;
}

.wrapper-invoice .invoice .invoice-information {
  float: right;
  text-align: right;
}

.wrapper-invoice .invoice .invoice-information b {
  color: "#0F172A";
}

.wrapper-invoice .invoice .invoice-information p {
  font-size: 14px;
  color: gray;
}

.wrapper-invoice .invoice .invoice-logo-brand h2 {
  text-transform: uppercase;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  color: "#0F172A";
}

.wrapper-invoice .invoice .invoice-logo-brand img {
  max-width: 100px;
  width: 100%;
  object-fit: fill;
}

.wrapper-invoice .invoice .invoice-head {
  display: flex;
  margin-top: 8vh;
}

.wrapper-invoice .invoice .invoice-head .head {
  width: 100%;
  box-sizing: border-box;
}

.wrapper-invoice .invoice .invoice-head .client-info {
  text-align: left;
}

.wrapper-invoice .invoice .invoice-head .client-info h2 {
  font-weight: 500;
  letter-spacing: 0.3px;
  font-size: 14px;
  color: "#0F172A";
}

.wrapper-invoice .invoice .invoice-head .client-info p {
  font-size: 14px;
  color: gray;
}

.wrapper-invoice .invoice .invoice-head .client-data {
  text-align: right;
}

.wrapper-invoice .invoice .invoice-head .client-data h2 {
  font-weight: 500;
  letter-spacing: 0.3px;
  font-size: 14px;
  color: "#0F172A";
}

.wrapper-invoice .invoice .invoice-head .client-data p {
  font-size: 14px;
  color: gray;
}

.wrapper-invoice .invoice .invoice-body {
  margin-top: 8vh;
}

.wrapper-invoice .invoice .invoice-body .table {
  border-collapse: collapse;
  width: 100%;
}

.wrapper-invoice .invoice .invoice-body .table thead tr th {
  font-size: 14px;
  border: 1px solid #dcdcdc;
  text-align: left;
  padding: 1vh;
  background-color: #eeeeee;
}

.wrapper-invoice .invoice .invoice-body .table tbody tr td {
  font-size: 14px;
  border: 1px solid #dcdcdc;
  text-align: left;
  padding: 1vh;
  background-color: #fff;
}

.wrapper-invoice .invoice .invoice-body .table tbody tr td:nth-child(2) {
  text-align: right;
}

.wrapper-invoice .invoice .invoice-body .flex-table {
  display: flex;
}

.wrapper-invoice .invoice .invoice-body .flex-table .flex-column {
  width: 100%;
  box-sizing: border-box;
}

.wrapper-invoice
  .invoice
  .invoice-body
  .flex-table
  .flex-column
  .table-subtotal {
  border-collapse: collapse;
  box-sizing: border-box;
  width: 100%;
  margin-top: 2vh;
}

.wrapper-invoice
  .invoice
  .invoice-body
  .flex-table
  .flex-column
  .table-subtotal
  tbody
  tr
  td {
  font-size: 14px;
  border-bottom: 1px solid #dcdcdc;
  text-align: left;
  padding: 1vh;
  background-color: #fff;
}

.wrapper-invoice
  .invoice
  .invoice-body
  .flex-table
  .flex-column
  .table-subtotal
  tbody
  tr
  td:nth-child(2) {
  text-align: right;
}

.wrapper-invoice .invoice .invoice-body .invoice-total-amount {
  margin-top: 1rem;
}

.wrapper-invoice .invoice .invoice-body .invoice-total-amount p {
  font-weight: bold;
  color: "#0F172A";
  text-align: right;
  font-size: 14px;
}

.wrapper-invoice .invoice .invoice-footer {
  margin-top: 4vh;
}

.wrapper-invoice .invoice .invoice-footer p {
  font-size: 14px;
  color: gray;
}

.copyright {
  margin-top: 2rem;
  text-align: center;
}

.copyright p {
  color: gray;
  font-size: 14px;
}

@media print {
  .table thead tr th {
    -webkit-print-color-adjust: exact;
    background-color: #eeeeee !important;
  }

  .copyright {
    display: none;
  }
}

.rtl {
  direction: rtl;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.rtl .invoice-information {
  float: left !important;
  text-align: left !important;
}

.rtl .invoice-head .client-info {
  text-align: right !important;
}

.rtl .invoice-head .client-data {
  text-align: left !important;
}

.rtl .invoice-body .table thead tr th {
  text-align: right !important;
}

.rtl .invoice-body .table tbody tr td {
  text-align: right !important;
}

.rtl .invoice-body .table tbody tr td:nth-child(2) {
  text-align: left !important;
}

.rtl .invoice-body .flex-table .flex-column .table-subtotal tbody tr td {
  text-align: right !important;
}

.rtl
  .invoice-body
  .flex-table
  .flex-column
  .table-subtotal
  tbody
  tr
  td:nth-child(2) {
  text-align: left !important;
}

.rtl .invoice-body .invoice-total-amount p {
  text-align: left !important;
}
