.pagination-container {
    display: flex;
    list-style-type: none;
}

.pagination-container .pagination-item {
    padding: 0 12px;
    height: 40px;
    text-align: center;
    margin: auto 2px;
    color: '#5C6970';
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 4px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
}

.pagination-container .pagination-item.dots:hover {
    background-color: transparent;
    cursor: default;
}

.pagination-container .pagination-item:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
}

.pagination-container .pagination-item.selected {
    background-color: '#5569ff';
    color: 'white';
}

.pagination-container .pagination-item .arrow::before {
    position: relative;
    /* top: 3pt;
	 Uncomment this to lower the icons as requested in comments*/
    content: '';
    /* By using an em scale, the arrows will size with the font */
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
}

.pagination-container .pagination-item .arrow.left {
    transform: rotate(-135deg) translate(-50%);
}

.pagination-container .pagination-item .arrow.right {
    transform: rotate(45deg);
}

.pagination-container .pagination-item.disabled {
    pointer-events: none;
}

.pagination-container .pagination-item.disabled .arrow::before {
    color: rgba(0, 0, 0, 0.43);
}

.pagination-container .pagination-item.disabled:hover {
    background-color: transparent;
    cursor: default;
}

@media (min-width: 375px) {
    .pagination-container {
        display: flex;
        list-style-type: none;
    }

    .pagination-container .pagination-item {
        padding: 0 12px;
        height: 32px;
        text-align: center;
        margin: auto 2px;
        color: '#303436';
        display: flex;
        box-sizing: border-box;
        align-items: center;
        letter-spacing: 0.01071em;
        border-radius: 2px;
        line-height: 1.43;
        font-size: 13px;
        min-width: 32px;
    }

    .pagination-container .pagination-item.dots:hover {
        background-color: transparent;
        cursor: default;
    }

    .pagination-container .pagination-item:hover {
        background-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
    }

    .pagination-container .pagination-item.selected {
        background-color: '#5569ff';
        color: 'white';
    }

    .pagination-container .pagination-item .arrow::before {
        position: relative;
        /* top: 3pt;
		 Uncomment this to lower the icons as requested in comments*/
        content: '';
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
    }

    .pagination-container .pagination-item .arrow.left {
        color: rgba(0, 0, 0, 1);
    }

    .pagination-container .pagination-item .arrow.right {
        transform: rotate(45deg);
    }

    .pagination-container .pagination-item.disabled {
        pointer-events: none;
    }

    .pagination-container .pagination-item.disabled .arrow::before {
        color: rgba(0, 0, 0, 0.43);
    }

    .pagination-container .pagination-item.disabled:hover {
        background-color: transparent;
        cursor: default;
    }
}